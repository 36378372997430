import * as React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import Testimonial from "../components/Testimonial"

// markup
const IndexPage = () => {
  return (
    <Layout>
      <div>
        <Container title="About me">
          <p className="mb-6 max-w-prose text-accent-text">I'm a <span className="font-bold">Senior Product Designer</span> based in <span className="font-bold">Glasgow, Scotland</span>. I've worked in various in‐house roles over the past six years, mainly as a designer,
            but I've also been a Head of Product and a Front‐end Developer.</p>
            
          <p className="mb-6 max-w-prose text-accent-text">I have a passion for <span className="font-bold">design systems</span>, <span className="font-bold">accessibility</span>, <span className="font-bold">research</span> and <span className="font-bold">user testing</span>.</p>

          <p className="max-w-prose text-accent-text">Work aside, I have an educational background in philosophy; I love traveling, hiking, camping, guitars, gaming and chess. I'm based in Glasgow, Scotland, where I live with my partner.</p>
        </Container>

        <Container secondaryContainer showFooter title="Testimonials">
          <div className="grid grid-cols-1 gap-12">
            <div>
              <Testimonial bg="purple" name="Kitty Goodwin" role="Director of Programs @ Sust Global" alt="Testimonial 1">
                <p className="mt-2 mb-4 font-bold text-lg">"He is incredibly personable and always supported efforts to connect with the team and build the company culture."</p>

                <p>"One of the things that stood out to me about Andrew was his ability to explain technical concepts to non-technical people in a clear and concise manner. This is a rare skill and one that is highly valuable in any organization."</p>
              </Testimonial>
            </div>

            <div>
            <Testimonial bg="green" name="Martin Keane" role="Lead PO @ Fuse Universal" alt="Testimonial 2">
              <p className="mt-2 mb-4 font-bold text-lg">"Andrew is the type of designer that every team should have."</p>

              <p>"He is always able to deliver beautiful, considered work. His empathy, understanding and knowledge have also proved exceptionally valuable in conducting user research. He is able to uncover insights which make a huge difference to the shape of the final product."</p>
            </Testimonial>
            </div>
            
            <div>
            <Testimonial bg="yellow" name="Cameron Graham" role="Co-Founder & CEO @ StoriiCare" alt="Testimonial 3">
              <p className="mt-2 mb-4 font-bold text-lg">"As a designer, I cannot recommend him enough. As an individual, he is professional and a pleasure to be around."</p>

              <p>"Andrew was our Designer and Front‐end Developer, later rising to our Head of Product role. In a startup, roles can often become all‐encompassing, and his willingness and ability to contribute to multiple aspects of the business was refreshing."</p>
            </Testimonial>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
