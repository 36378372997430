import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Testimonial = ({children, name, role, alt, bg}) => {
    const renderStaticImage = () => {
      if (name === "Kitty Goodwin") {
        return <StaticImage placeholder="blurred" src="../images/kg.png" alt={alt} className="rounded-full h-20 w-20 object-cover ml-auto mb-4 shadow-lg" />;
      } else if (name === "Martin Keane") {
        return <StaticImage placeholder="blurred" src="../images/mk.jpg" alt={alt} className="rounded-full h-20 w-20 object-cover ml-auto mb-4 shadow-lg" />;
      } else {
        return <StaticImage placeholder="blurred" src="../images/cg.jpg" alt={alt} className="rounded-full h-20 w-20 object-cover ml-auto mb-4 shadow-lg" />;
      }
    }

    return (
        <div className={'flex flex-wrap bg-gradient-to-r from-' + bg + '-900 to-' + bg + '-500 rounded-xl shadow-lg text-white p-10'}>
            <div className="w-full md:w-2/3">
                {children}
            </div>

            <div className="w-full md:w-1/3 text-right">
                <div className="mt-10 md:mt-0 pl-8 text-accent">
                    {renderStaticImage()}

                    <p className="max-w-prose text-lg font-bold">{name}</p>
                
                    <p className="max-w-prose text-sm">{role}</p>
                </div>
            </div>
        </div>
    )
}

export default Testimonial